<!-- <header>
  <div id="carousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carousel" data-slide-to="0" class="active"></li>
      <li data-target="#carousel" data-slide-to="1"></li>
      <li data-target="#carousel" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
      <div class="carousel-item active" style="background-image: url('./assets/img/homepage/binnen5.jpeg')">
        <div class="carousel-caption">
          <h1 class="homepage-text">Welkom bij Will's Pancake House!</h1>
        </div>
      </div>
      <div class="carousel-item" style="background-image: url('./assets/img/homepage/pan1.jpeg')">
        <div class="carousel-caption">
          <h1 class="display-4 homepage-text">De lekkerste pannenkoeken van Den Haag!</h1>
        </div>
      </div>
      <div class="carousel-item" style="background-image: url('./assets/img/homepage/binnen4.jpeg')">
        <div class="carousel-caption">
          <h1 class="display-4 homepage-text">Kom je gezellig bij ons langs?</h1>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</header> -->

<div class="container-fluid pb-3">
  <div class="row homepage-row0 justify-content-center">
    <!-- <div class="col"> -->
    <div class="home-special-div">
      <div class="row d-flex align-items-end justify-content-center h-100">
        <!-- cta/nav hybrid hotbar -->
        <div class="col-12 d-flex align-items-center justify-content-center" style="height: 60px; margin-bottom:-30px;">

          <div class="mx-auto btn-group">
            <!-- <div class="background-overlay-dark"
              style="position:absolute;left:0;top:0;height:100%;width:100%;z-index:1;pointer-events:none;"></div> -->

            <a routerLink="/reserveren" [routerLinkActive]="['isActive']"
              class="btn btn-willshomepage py-2 px-3 rounded-left">
              <i class="fas fa-utensils mr-2"></i>
              <span class="d-none d-md-inline">Reserveren</span>
            </a>
            <a href="tel:0703639370" class="btn btn-willshomepage py-2 px-3">
              <i class="fas fa-phone mr-1"></i>
              <span class="d-none d-md-inline">
                <!-- 070-3639370 -->
              </span>
            </a>

            <!-- <a href="#adres" class="btn btn-willshomepage py-2 px-3">
              <i class="fas fa-location-arrow"></i>
            </a> -->

            <a href="mailto:info@willspancakehouse.com" class="btn btn-willshomepage py-2 px-3">
              <i class="fas fa-envelope"></i>
            </a>

          </div>
        </div>

      </div>
      <!-- </div> -->
      <!-- <h1 class="willscolor wills-h1-font text-center">Will's Pancake House</h1> -->
    </div>
  </div>

  <div class="row homepage-row1">
    <div class="container">
      <div class="row ">
        <div class="col-12 pt-5">
          <h1 class="willscolor wills-h1-font text-center">Will's Pancake House</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-xl-6">
          <p>Al meer dan 35 jaar bakken wij met veel plezier en liefde pannenkoeken en poffertjes. In ons gezellige
            restaurant in het centrum van Den Haag staan wij 7 dagen per week voor al onze klanten klaar.
            <br>
            Bij ons eet je de lekkerste pannenkoeken en poffertjes in heel Den Haag. Van een heerlijk simpele naturel
            pannenkoek tot een kaas-spek pannenkoek of poffertjes met verse aardbeijen.
          </p>
          <p>
            
          </p>

          <!-- <p>Al meer dan 35 jaar bakken wij met enorm veel liefde pannenkoeken en poffertjes. Midden in het centrum van
            Den Haag, bakken wij de lekkerste pannenkoeken voor jou! Een heerlijke naturel pannenkoek, een kaas-spek
            pannenkoek of een portie poffertjes met verse aardbeien. </p>
          <p>Wij zijn zeven dagen per week open. Op onze <a routerLink='/contact'>contact</a> pagina zijn de
            openingstijden te vinden. </p> -->
          <div class="text-center mx-auto d-none d-md-block">
            <img src="./assets/img/homepage/pan1.jpeg" class="img-fluid rounded" width="75%">
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-6">
          <div class="row d-flex justify-content-center">
            <!-- <img src="./assets/img/homepage/wills1.jpeg" class="img-fluid home-img"> -->
            <div class="text-center">
              <img src="./assets/img/homepage/wills1_cropped_smaller.jpg" class="img-fluid home-img rounded"
                width="85%">
            </div>
          </div>
        </div>
      </div>
      <div class="row">


      </div>
    </div>
  </div>
</div>