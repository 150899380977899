import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})

@HostListener('window:scroll', ['$event'])
export class NavigationComponent implements OnInit {
  showNavLogoBig : boolean;
  constructor(private router: Router) { 

  }

  ngOnInit(): void {
  
    this.router.events.subscribe( (e) => {
      if (e instanceof NavigationStart) {
        if (e.url === "/" || e.url ==="/home") {
            this.showNavLogoBig = true;
        } else {
            this.showNavLogoBig = false;
        }
      } else {
        // console.log("else" + e);
      }
    });
  }

//   collapsed = true;
//     toggleCollapsed(): void {
//       this.collapsed = !this.collapsed;
//     }

//     @HostListener('window:scroll', ['$event'])

// onWindowScroll() {
//     let element = document.querySelector('.navbar') as HTMLElement;
//     let test = document.querySelector('.tst123') as HTMLElement;
//     console.log("Hoi")
//     if (window.pageYOffset > element.clientHeight) {
//       element.classList.add('navbar-show');
      
//       test.classList.add('hide12');
//       console.log("1")
//     } else {
//       element.classList.remove('navbar-hide');
//       test.classList.remove('hide12');
//       console.log("2")
//     }
//   }
}
