import { MenuDataModel } from '../_models/menudatamodel';

export const TostiEngData: MenuDataModel[] = [
    { id: 1, name: 'Ham or Cheese', price: '4,50', euros: '4', cents: '50' },
    { id: 2, name: 'Ham and Cheese', price: '5,50', euros: '5', cents: '50' },
    { id: 3, name: 'Cheese with Tomato or Pineapple or Ginger or Salami', price: '5,75', euros: '5', cents: '75' },
    { id: 4, name: 'Ham, Cheese and Egg', price: '6,00', euros: '6', cents: '00' },
    { id: 5, name: 'French Cheese, Walnuts and Honey', price: '5,50', euros: '5', cents: '50' },
    { id: 6, name: 'French Cheese and Sambal', price: '4,95', euros: '4', cents: '95' },
    { id: 7, name: 'Tuna, Cheese and Egg', price: '6,75', euros: '6', cents: '75' },
    { id: 8, name: 'Cheese and Turkey Breast (Halal)', price: '5,95', euros: '5', cents: '95' },
    { id: 9, name: 'Ham, Cheese, Bacon, Mushrooms and Egg', price: '7,50', euros: '7', cents: '50' },
    { id: 10, name: 'Mozzarella, Pesto and Tomato', price: '6,50', euros: '6', cents: '50' },

]
