import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { NavigationComponent } from './navigation/navigation.component';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactpageComponent } from './contactpage/contactpage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { ReserverenComponent } from './reserveren/reserveren.component';
import { FormComponentComponent } from './form-component/form-component.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FooterComponent } from './footer/footer.component';
import { DatePipe } from '@angular/common';
import { ReserverenHoverComponent } from './reserveren-hover/reserveren-hover.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ErrorpageComponent,
    NavigationComponent,
    MenuComponent,
    ContactpageComponent,
    ReserverenComponent,
    FormComponentComponent,
    FooterComponent,
    ReserverenHoverComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'nl' }, DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
