import { MenuDataModel } from '../_models/menudatamodel';

export const UitsmijterData: MenuDataModel[] = [
    { id: 1, name:'Spek en Kaas', price: '10,50', euros: '10', cents: '50'},
    { id: 2, name:'Ham en Kaas', price: '10,50', euros: '10', cents: '50'},
    { id: 3, name:'Boerenomelet', price: '11,50', euros: '11', cents: '50'},
    { id: 4, name:'Boerenomelet, Spek en Kaas of Ham en Kaas', price: '13,25', euros: '13', cents: '25'},
    { id: 5, name:'Omelet', price: '9,25', euros: '9', cents: '25'},
    { id: 6, name:'Omelet Kaas en Tomaat', price: '10,50', euros: '10', cents: '50'},
    { id: 7, name:'Omelet Kaas en Champignon', price: '12,25', euros: '12', cents: '25'},
    { id: 8, name:'Omelet Spek en kaas', price: '11,50', euros: '11', cents: '50'},
    { id: 9, name:'Scrambles, Ei, Toast en Spek', price: '11,50', euros: '11', cents: '50'},
    
]