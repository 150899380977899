import { MenuDataModel } from '../_models/menudatamodel';

export const UitsmijterEngData: MenuDataModel[] = [
    { id: 1, name: 'Bacon and Cheese', price: '10,50', euros: '10', cents: '50' },
    { id: 2, name: 'Ham and Cheese', price: '10,50', euros: '10', cents: '50' },
    { id: 3, name: 'Omelet with assorted Vegetables', price: '11,50', euros: '11', cents: '50' },
    { id: 4, name: 'Omelet with assorted Vegetables with Bacon and Cheese', price: '13,25', euros: '13', cents: '25' },
    { id: 5, name: 'Omelet', price: '9,25', euros: '9', cents: '25' },
    { id: 6, name: 'Omelet Cheese and Tomato', price: '10,50', euros: '10', cents: '50' },
    { id: 7, name: 'Omelet Cheese and Mushrooms', price: '12,25', euros: '12', cents: '25' },
    { id: 8, name: 'Omelet, Bacon and Cheese', price: '11,50', euros: '11', cents: '50' },
    { id: 9, name: 'Scrambles Eggs, Toast, Bacon', price: '11,50', euros: '11', cents: '50' },
]
