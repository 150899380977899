import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { MenuComponent } from './menu/menu.component';
import { ContactpageComponent } from './contactpage/contactpage.component';
import { ReserverenComponent } from './reserveren/reserveren.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'menu',redirectTo:'menu/nl'},
  { path: 'home', component: HomeComponent },
  { path: 'menu/:language', component: MenuComponent },
  
  { path: 'reserveren', component: ReserverenComponent },
  { path: 'contact', component: ContactpageComponent },
  { path: '404', component: ErrorpageComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
