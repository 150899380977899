<div class="container">
  <div class="row">
    <div class="col p-4">
      <h1 class="contactpage-header-text text-center willscolor text-uppercase font-weight-bold">Contact</h1>
    </div>
  </div>
  <div class="row contact-row-1 pb-3">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <h1 class="willscolor wills-header-font">Kom gezellig bij ons langs!</h1>
      <p>Kom je gezellig pannenkoeken eten bij ons? Wij zijn 7 dagen per week geopend.
        Heb je een vraag? Kom gerust een keer binnen lopen of geef ons een belletje.
      </p>
      <br>
      <p><strong>Will's Pancake House</strong><br>
        Gedempte Gracht 80<br>
        2512 KB Den Haag</p>
      <p> <i class="fas fa-phone"></i>&nbsp;<a href="tel:+31703639370 ">070-3639370</a> <br>
        <i class="fas fa-envelope"></i>&nbsp;<a href="mailto:info@willspancakehouse.com">info@willspancakehouse.com</a>
      </p>
      <br>
      <strong>Onze openingstijden:</strong>

      <div class="row" *ngFor='let openingstijd of openingstijdenArray'>
        <div class="col-6">{{openingstijd.dag}}:</div>
        <div class="col-6">{{openingstijd.open}}:00 - {{openingstijd.sluit}}:00</div>
      </div>
      <h2 class="willscolor wills-header-font pt-2">Heb je een vraag? Laat het ons weten.</h2>
      <p>Wij beantwoorden met plezier al je vragen. Je kunt ons bereiken via email, telefoon
        of je kunt gebruik maken van het contact formulier. Wij proberen zo snel mogelijk te reageren.
      </p>
      <p>
        Geen vragen en wil je meteen reserveren? <a routerLink="/reserveren">Klik hier om te reserveren! &nbsp;<i
            class="far fa-calendar-check"></i></a>
      </p>
    </div>
    <div
      class="col-sm-12 col-md-5 col-lg-5 col-xl-5 mx-auto order-lg-1 order-xl-1 order-md-1 order-sm-2 order-2 page-contact-form">
      <app-form-component [formtype]="'contact'"></app-form-component>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row h-25">
    <div class="col-12 pl-0 pr-0">
      <div class="map-responsive">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.151111908378!2d4.312862715758621!3d52.0769789797327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b72396a699d3%3A0x3927c9503593c4b4!2sWill&#39;s%20Pancakehouse!5e0!3m2!1sen!2snl!4v1588798627463!5m2!1sen!2snl&q=Eiffel+Tower,Paris+France"
          width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
          tabindex="0"></iframe>
      </div>
    </div>
  </div>
</div>