import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class MailserviceService {

  mailUrl = "/sendEmail.php";
  constructor(public http: HttpClient) { }

  sendMail(email: string, content) {
    return this.http.post<any>(this.mailUrl,content)
      .pipe(map(response => {
        console.log(response)
        return response;
      }));
  }
}
