import { MenuDataModel } from '../_models/menudatamodel';

export const PoffertjesData: MenuDataModel[] = [
    { id: 1, name: 'Klein', price: '7,75', euros: '7', cents: '75' },
    { id: 2, name: 'Middel', price: '9,75', euros: '9', cents: '75' },
    { id: 3, name: 'Groot', price: '12,75', euros: '12', cents: '75' },
    { id: 4, name: 'Grand Marnier', price: '10,50', euros: '10', cents: '50' },
    { id: 5, name: 'Cointreau', price: '10,25', euros: '10', cents: '25' },
    { id: 6, name: 'Poffertjes met Slagroom', price: '9,50', euros: '9', cents: '50' },
    { id: 7, name: 'Poffertjes Advocaat met Slagroom', price: '10,95', euros: '10', cents: '95' },
    { id: 8, name: 'Fruitcocktail, Mandarijn, Gember, Ananas', price: '9,75', euros: '9', cents: '75' },
    { id: 9, name: 'Appel compôte', price: '10,25', euros: '10', cents: '25' },
    { id: 10, name: 'Aardbei en Slagroom', price: '10,95', euros: '10', cents: '95' },
    { id: 11, name: 'Banaan en Slagroom', price: '10,95', euros: '10', cents: '95' },
    { id: 12, name: 'Ananas en Slagroom', price: '10,95', euros: '10', cents: '95' },
    { id: 13, name: 'Perzik en Slagroom', price: '10,95', euros: '10', cents: '95' },
    { id: 14, name: 'Warme kersen, ijs en Slagroom', price: '12,25', euros: '12', cents: '25' },
    { id: 15, name: 'Middel portie Aardbeien met Slagroom', price: '13,25', euros: '13', cents: '25' },
    { id: 16, name: 'Grote portie Aardbeien met Slagroom', price: '19,75', euros: '19', cents: '75' },
    { id: 17, name: 'Poffertjes, Banaan, ijs, Slagroom, Nutella', price: '13,75', euros: '13', cents: '75' },

]