import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Openingstijden } from '../_core/_data/openingstijden';
import { MailserviceService } from '../_core/_services/mailservice.service';

@Component({
  selector: 'app-contactpage',
  templateUrl: './contactpage.component.html',
  styleUrls: ['./contactpage.component.css']
})
export class ContactpageComponent implements OnInit {

  contactForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  hasServerError = false;
  error = '';
  email: string;
  name: string;
  message: string;
  submittedEmail: boolean = false;
  responseMessage: string;
  test = new UntypedFormControl(new Date());
  list;
  openingstijdenArray = Openingstijden;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private mailService: MailserviceService
  ) {
  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      naam: [''],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      bericht: ['', [Validators.required, Validators.minLength(6)]],
      telefoonnummer: ['', []],
      aantalPersonen: ['', []],
      date: [this.test, []]
    });
    this.list = this.generate_series(30);
  }

  isPasswordMatch(): boolean { // could be turned into "custom" form validator
    return this.contactForm.controls.password.value === this.contactForm.controls.confirmPassword.value;
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;
    // this.confirmPassword = this.isPasswordMatch();
    // if (this.registerForm.invalid || !this.confirmPassword) {
    //   return;
    // }
    // registeredUser.email = this.registerForm.value.email;
    // registeredUser.firstName = this.registerForm.value.firstName;
    // registeredUser.lastName = this.registerForm.value.lastName;

    this.loading = true;
  }

  generate_series(step) {
    const dt = new Date(1970, 0, 1);
    const rc = [];

    var result = [];                      // Results will go here
    var nowHour = new Date().getHours();  // Get current hour of the day
    // Loop from current hour number to 23
    for (var i = nowHour; i < 24; i++) {
      result.push(i + "00");  // Put loop counter into array with "00" next to it
    }


    while (dt.getDate() === 1) {
      rc.push(dt.toLocaleTimeString('en-NL'));
      dt.setMinutes(dt.getMinutes() + step);
    }
    return rc;
  }

  sendMail(): void {
    console.log("test");
    this.email = "hpierce@example.com";
    this.name = "Hayden Pierce";
    this.message = "Hello, this is Hayden.";

    let postVars = {
      email: this.email,
      name: this.name,
      message: this.message
    };

    this.mailService.sendMail('test', postVars).pipe(first())
      .subscribe(
        data => {
          this.submittedEmail = true;
          this.responseMessage = data;
        });
  }


}
