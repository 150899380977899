<div class="container">
    <div class="row menu-row1">
        <div class="col p-4">
            <h1 class="reserveren-header-text text-center willscolor text-uppercase font-weight-bold">Reserveren</h1>
        </div>
    </div>
    <div class="row reserveren-row-padding">
        <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <h1 class="willscolor wills-header-font">Een tafeltje reserveren?</h1>
            <p>Iedereen is altijd welkom bij ons. Maar wil je zeker weten dat er een tafeltje vrij is voor je? Maak dan
                een reservering en dan zorgen wij voor de rest! Als je vragen hebt, stel ze gerust dan reageren wij zo
                snel mogelijk.</p>
            <p>Kom je met meer dan 8 personen? Laat het ons even weten. Bij grote groepen doen we dit vaak in overleg om
                te kunnen garanderen dat er plek is.</p>

            <p>
                Nog niet reserveren maar heb je toch een vraag voor ons? Ga dan naar onze <a
                    routerLink="/contact">contact</a>
                pagina en stel je vraag daar. Dan nemen wij zo snel mogelijk contact met je op.
            </p>

            <div class="row pt-2">
                <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <h1 class="willscolor wills-header-font">Heb je iets te vieren? <i class="fas fa-birthday-cake"></i>
                    </h1>
                    <p>Wij hebben altijd zin in een feestje. Heb je zelf iets te vieren of wil je tijdens een
                        kinderfeestje bij ons pannenkoeken en poffertjes komen eten? Neem even contact met ons op, zodat
                        wij alles tot in de puntjes voor jou kunnen regelen! </p>

                </div>
                <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 mx-auto d-none d-md-block">
                    <img src="./assets/img/homepage/pan1.jpeg" class="img-fluid home-img rounded" width="">
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <p>Klik <a routerLink="/contact">hier</a> om te zien waar ons restaurant zich bevind en wat onze
                        openingstijden
                        zijn.
                    </p>
                </div>
            </div> -->

            <div class="row pt-2">
                <div class="col-12">
                    <h2 class="willscolor wills-header-font">Tot Snel!</h2>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-4 col-xl-4 mx-auto pt-2 pb-4">
            <app-form-component [formtype]="'reserveren'"></app-form-component>
        </div>
    </div>

</div>