import { MenuDataModel } from '../_models/menudatamodel';

export const PannenkoekenData: MenuDataModel[] = [
  { id: 1, name: 'Kinderpannenkoek', price: '6,75', euros: '6', cents: '75' },
  { id: 2, name: 'Kinderpannenkoek met verrassing', price: '7,50', euros: '7', cents: '50' },
  { id: 3, name: 'Naturel', price: '7,75', euros: '7', cents: '75' },
  { id: 4, name: 'Nutella', price: '8,50', euros: '8', cents: '50' },
  { id: 5, name: 'Banaan of Appel of Ananas of Mandarijnen', price: '9,50', euros: '9', cents: '50' },
  { id: 6, name: 'Banaan, ijs, Slagroom, Nutella', price: '13,75', euros: '13', cents: '75' },
  { id: 7, name: 'Fruitcocktail of Rozijnen of Gember', price: '9,50', euros: '9', cents: '50' },
  { id: 8, name: 'Ham of Kaas of Spek', price: '10,50', euros: '10', cents: '50' },
  { id: 9, name: 'Appel en Rozijnen', price: '10,50', euros: '10', cents: '50' },
  { id: 10, name: 'Kaas en Salami', price: '11,50', euros: '11', cents: '50' },
  { id: 11, name: 'Ham en Kaas', price: '11,50', euros: '11', cents: '50' },
  { id: 12, name: 'Spek en Kaas', price: '11,50', euros: '11', cents: '50' },
  { id: 13, name: 'Spek en Rozijnen', price: '11,50', euros: '11', cents: '50' },
  { id: 14, name: 'Spek en Appel', price: '11,50', euros: '11', cents: '50' },
  { id: 15, name: 'Spek en Gember', price: '11,50', euros: '11', cents: '50' },
  { id: 16, name: 'Spek met Champignons', price: '12,00', euros: '12', cents: '00' },
  { id: 17, name: 'Champignons', price: '10,50', euros: '10', cents: '50' },
  { id: 18, name: 'Champignons en Kaas', price: '12,00', euros: '12', cents: '00' },
  { id: 19, name: 'Champignons en Ham', price: '12,00', euros: '12', cents: '00' },
  { id: 20, name: 'Champignons, Kaas, Ham', price: '13,24', euros: '13', cents: '24' },
  { id: 21, name: 'Champignons, Spek, Kaas', price: '13,25', euros: '13', cents: '25' },
  { id: 22, name: 'Champignons, Spek, Ham, Kaas', price: '14,25', euros: '14', cents: '25' },
  { id: 23, name: 'American (met ijs en fruitcocktail)', price: '11,25', euros: '11', cents: '25' },
  { id: 24, name: 'Warme Kersen, ijs en slagroom', price: '12,25', euros: '12', cents: '25' },
  { id: 25, name: 'Hartig (met ham, kaas, uien, paprika en champignons)', price: '14,25', euros: '14', cents: '25' },
  { id: 26, name: 'Tonijn, kaas, ui en knoflook', price: '13,75', euros: '13', cents: '75' },
  { id: 27, name: 'Verse Aardbeien en echte slagroom', price: '10,95', euros: '10', cents: '95' },
  { id: 28, name: 'Kaas en Kalkoenfilet (Halal)', price: '12,50', euros: '12', cents: '50' },
];