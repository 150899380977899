import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reserveren',
  templateUrl: './reserveren.component.html',
  styleUrls: ['./reserveren.component.css']
})
export class ReserverenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
