<div class="container-fluid">
    <div class="row menu-row1">
        <div class="col p-4">
            <h1 class="menupage-header-text text-center willscolor text-uppercase font-weight-bold">Menu</h1>
            <br>
            <div class="menu-language text-center">
                <a href="#" routerLink="/menu/nl" class="menu-language-a"
                    [ngClass]="dutchActive === true ? 'isActive' : 'isNotActive'">Nederlands</a>
                <span class="menu-language-span">/</span>
                <a href="#" routerLink="/menu/en" class="menu-language-a"
                    [ngClass]="englishActive === true ? 'isActive' : 'isNotActive'">English</a>
            </div>
        </div>
    </div>
    <div class="row menu-row2 justify-content-around">
        <div class="col-sm-6 col-md-4">
            <button class="menu-header-button" (click)="hidePannenkoek($event)">{{pannenkoekTitel}}</button>
            <ul class="menu" [ngClass]="hiddenPannenkoek ? 'ul-content-hide' : ''">
                <!-- <h1 class="menu-header-text text-center">Pannenkoeken</h1> -->
                <li *ngFor="let pannenkoek of pannenkoeken"><strong>{{pannenkoek.name}}</strong>
                    <em>{{pannenkoek.euros}}<sup>{{pannenkoek.cents}}</sup></em>
                </li>

            </ul>
        </div>
        <div class="col-sm-6 col-md-4">
            <button class="menu-header-button" (click)="hidePoff($event)">{{poffTitel}}</button>
            <ul class="menu ul-content" [ngClass]="hiddenPoff ? 'ul-content-hide' : ''">

                <!-- <h1 class="menu-header-text text-center">Poffertjes</h1> -->
                <li *ngFor="let pof of poffertjes"><strong>{{pof.name}}</strong>
                    <em>{{pof.euros}}<sup>{{pof.cents}}</sup></em>
                </li>

            </ul>
            <div class="text-center mx-auto d-none d-md-block">
                <img class="rounded" src="./assets/img/menupage/pan1.jpeg" width="80%">
            </div>
        </div>
    </div>
    <div class="row menu-row2 justify-content-around">
        <div class="col-sm-6 col-md-4">
            <button class="menu-header-button" (click)="hideEi($event)">{{uitsmijtersTitel}}</button>
            <ul class="menu" [ngClass]="hiddenUitsmijter ? 'ul-content-hide' : ''">
                <!-- <h1 class="menu-header-text text-center">Uitsmijters</h1> -->
                <li *ngFor="let uitsmijt of uitsmijters"><strong>{{uitsmijt.name}}</strong>
                    <em>{{uitsmijt.euros}}<sup>{{uitsmijt.cents}}</sup></em>
                </li>

            </ul>
        </div>
        <div class="col-sm-6 col-md-4">
            <button title="Expand or collapse" class="menu-header-button"
                (click)="hideTosti($event)">{{tostiTitel}}</button>
            <ul class="menu" [ngClass]="hiddenTosti ? 'ul-content-hide' : ''">
                <!-- <h1 class="menu-header-text text-center">Tosti's</h1> -->
                <li *ngFor="let tosti of tostis"><strong>{{tosti.name}}</strong>
                    <em>{{tosti.euros}}<sup>{{tosti.cents}}</sup></em>
                </li>

            </ul>
        </div>
    </div>
</div>