import { MenuDataModel } from '../_models/menudatamodel';

export const PoffertjesEngData: MenuDataModel[] = [
    { id: 1, name: 'Small portion', price: '7,75', euros: '7', cents: '75' },
    { id: 2, name: 'Middle portion', price: '9,75', euros: '9', cents: '75' },
    { id: 3, name: 'Large portion', price: '12,75', euros: '12', cents: '75' },
    { id: 4, name: 'Grand Marnier', price: '10,50', euros: '10', cents: '50' },
    { id: 5, name: 'Cointreau', price: '10,25', euros: '10', cents: '25' },
    { id: 6, name: 'Poffertjes with whipped Cream', price: '9,50', euros: '9', cents: '50' },
    { id: 7, name: 'Poffertjes Advocaat with whipped Cream', price: '10,95', euros: '10', cents: '95' },
    { id: 8, name: 'Fruitcocktail, Mandarin, Ginger, Pineapple', price: '9,75', euros: '9', cents: '75' },
    { id: 9, name: 'Apple Compôte', price: '10,25', euros: '10', cents: '25' },
    { id: 10, name: 'Strawberries and whipped Cream', price: '10,95', euros: '10', cents: '95' },
    { id: 11, name: 'Banana and whipped Cream', price: '10,95', euros: '10', cents: '95' },
    { id: 12, name: 'Pineapple and whipped Cream', price: '10,95', euros: '10', cents: '95' },
    { id: 13, name: 'Peaches and whipped Cream', price: '10,95', euros: '10', cents: '95' },
    { id: 14, name: 'Hot Cherries, Ice and whipped Cream', price: '12,25', euros: '12', cents: '25' },
    { id: 15, name: 'Middle portion strawberry and whipped Cream', price: '13,25', euros: '13', cents: '25' },
    { id: 16, name: 'Large portion strawberry and whipped Cream ', price: '19,75', euros: '19', cents: '75' },
    { id: 17, name: 'Poffertjes, Banana, Ice, whipped Cream and Nutella', price: '13,75', euros: '13', cents: '75' },


]