<div class="container">
    <div class="row w-100 pt-4">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="footer_header text-center"> <img src="./assets/img/logo/logo_100px.svg" width="" alt="">
            </div>
        </div>
    </div>
    <hr class="shine">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
            <h2 class="footer-text-color">Contact</h2>
            <p class="footer_p">
                Gedempte Gracht 80<br>
                2512 KB Den Haag<br>
                <i class="fas fa-envelope"></i>&nbsp;<a
                    href="mailto:info@willspancakehouse.com">info@willspancakehouse.com</a><br>
                <i class="fas fa-phone"></i>&nbsp;<a href="tel:+31703639370 ">070-3639370</a>

            </p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 footer-text-color text-center">
            <h2>Openingstijden</h2>
            <div class="row" *ngFor='let openingstijd of openingstijdenArray'>
                <div class="col-6 dag-column">{{openingstijd.dag}}:</div>
                <div class="col-6 tijd-column">{{openingstijd.open}}:00 - {{openingstijd.sluit}}:00</div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
            <h2 class="footer_6">Sitemap</h2>
            <ul class="list-unstyled">
                <li class="footer-li-item"><a class="footer-a-item" routerLink="home" routerLinkActive="active">Home</a>
                </li>
                <li class="footer-li-item"><a class="footer-a-item" routerLink="menu" routerLinkActive="active">Menu</a>
                </li>
                <li class="footer-li-item"><a class="footer-a-item" routerLink="reserveren"
                        routerLinkActive="active">Reserveren</a>
                </li>
                <li class="footer-li-item"><a class="footer-a-item" routerLink="contact"
                        routerLinkActive="active">Contact</a>
                </li>

            </ul>
        </div>
    </div>

    <div class="row footer-bottom-row">
        <div class="col-12 text-center trade-mark-txt pb-2">
            <hr class="footer-hr">
            © {{thisyear}} willspancakehouse.com
        </div>
    </div>
</div>