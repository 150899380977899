import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Openingstijden } from '../_core/_data/openingstijden';
import { OpeningstijdenModel } from '../_core/_models/openingstijdenmodel';

@Component({
  selector: 'app-reserveren-hover',
  templateUrl: './reserveren-hover.component.html',
  styleUrls: ['./reserveren-hover.component.css']
})
export class ReserverenHoverComponent implements OnInit {
  WillsForm: UntypedFormGroup;
  minDate: Date;
  maxDate: Date;
  selectedDay: number;
  openingstijdenArray = Openingstijden;
  selectedOpeninsTijd: OpeningstijdenModel;
  timeList;
  datum: Date;
  datumString: String;
  // selectedDay: number;
  isOpen: Boolean = true;

  email: string;
  name: string;
  message: string;
  subject: string;
  responseMessage: string;


  constructor(private formBuilder: UntypedFormBuilder, private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.createForm();

  }

  setOpen() {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }

  }

  closePopup() {
    this.setOpen();
  }

  createForm() {
    this.WillsForm = this.formBuilder.group({
      naam: [''],
      // email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      email: ['', [Validators.required, Validators.email]],
      bericht: ['', []],
      telefoonnummer: ['', []],
      aantalpersonen: ['', [Validators.required]],
      datum: [this.minDate, [Validators.required]],
      tijd: [, [Validators.required]],
      selectedformtype: ['reserveren', []]
    });

    this.WillsForm.get("datum").valueChanges.subscribe(x => {
      console.log('firstname value changed');
      console.log(x);
      console.log(x.getDay());
      this.selectedDay = x.getDay();
      this.selectedOpeninsTijd = this.getOpeningstijden(x.getDay());
      this.getNewTimeList(this.selectedOpeninsTijd.open, this.selectedOpeninsTijd.sluit);
    });
  }

  sendMail() {
    this.datum = this.WillsForm.controls['datum'].value;
    if (this.datum == null) {
      return;
    }

    this.datumString = this.datepipe.transform(this.datum, 'dd-MMM-yyyy');
    console.log("Datum:" + this.datumString);
    this.email = this.WillsForm.controls['email'].value;
    this.name = this.WillsForm.controls['email'].value;
    this.subject = "Reservering - " + this.datumString;
    this.message =
      `
    Naam: ${this.WillsForm.controls['naam'].value}
    Emailadres: ${this.WillsForm.controls['email'].value}
    Telefoonnummer: ${this.WillsForm.controls['telefoonnummer'].value}

    Datum: ${this.datumString}
    Tijd: ${this.WillsForm.controls['tijd'].value}
    Aantal Personen: ${this.WillsForm.controls['aantalpersonen'].value}

    Bericht: ${this.WillsForm.controls['bericht'].value}
    `
  }

  getNewTimeList(open: number, closed: number) {
    var arr = [], i, j;
    for (i = open; i <= closed - 1; i++) {
      for (j = 0; j < 2; j++) {
        arr.push(i + ":" + (j === 0 ? "00" : 30 * j));
      }
    }
    this.timeList = arr;
  }

  getOpeningstijden(id): OpeningstijdenModel {
    return this.openingstijdenArray.find(x => x.id === id);
  }

}
