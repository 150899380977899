import { MenuDataModel } from '../_models/menudatamodel';

export const PannenkoekenEngData: MenuDataModel[] = [
    { id: 1, name:'Childrens pancake', price: '6,75', euros: '6', cents: '75'},
    { id: 2, name:'Childrens pancake with a suprise', price: '7,50', euros: '7', cents: '50'},
    { id: 3, name:'Naturel', price: '7,75', euros: '7', cents: '75'},
    { id: 4, name:'Nutella', price: '8,50', euros: '8', cents: '50'},
    { id: 5, name:'Banana or Apple or Pineapple or Mandarin', price: '9,50', euros: '9', cents: '50'},
    { id: 6, name:'Banana, Ice, Whipped Cream, Nutella', price: '13,75', euros: '13', cents: '75'},
    { id: 7, name:'Fruitcocktail or Raisin or Ginger', price: '9,50', euros: '9', cents: '50'},
    { id: 8, name:'Ham or Cheese or Bacon', price: '10,50', euros: '10', cents: '50'},
    { id: 9, name:'Apple and Raisin', price: '10,50', euros: '10', cents: '50'},
    { id: 10, name:'Cheese and Salami', price: '11,50', euros: '11', cents: '50'},
    { id: 11, name:'Ham and Cheese', price: '11,50', euros: '11', cents: '50'},
    { id: 12, name:'Bacon and Cheese', price: '11,50', euros: '11', cents: '50'},
    { id: 13, name:'Bacon and Raisin', price: '11,50', euros: '11', cents: '50'},
    { id: 14, name:'Bacon and Apple', price: '11,50', euros: '11', cents: '50'},
    { id: 15, name:'Bacon and Ginger', price: '11,50', euros: '11', cents: '50'},
    { id: 16, name:'Bacon and Mushrooms', price: '12,00', euros: '12', cents: '00'},
    { id: 17, name:'Mushrooms', price: '10,50', euros: '10', cents: '50'},
    { id: 18, name:'Mushrooms and Cheese', price: '12,00', euros: '12', cents: '00'},
    { id: 19, name:'Mushrooms and Ham', price: '12,00', euros: '12', cents: '00'},
    { id: 20, name:'Mushrooms, Cheese and Ham', price: '13,24', euros: '13', cents: '24'},
    { id: 21, name:'Mushrooms, Bacon, Cheese', price: '13,25', euros: '13', cents: '25'},
    { id: 22, name:'Mushrooms, Bacon, Ham, Cheese', price: '14,25', euros: '14', cents: '25'},
    { id: 23, name:'American (with icecream and fruitcocktail)', price: '11,25', euros: '11', cents: '25'},
    { id: 24, name:'Hot Cherries, Icecream and Whipped Cream', price: '12,25', euros: '12', cents: '25'},
    { id: 25, name:'Savory (Ham, Cheese, Onions, Paprika and Mushrooms)', price: '14,25', euros: '14', cents: '25'},
    { id: 26, name:'Tuna, Cheese, Onions and Garlic', price: '13,75', euros: '13', cents: '75'},
    { id: 27, name:'Fresh Strawberries and pure Cream', price: '10,95', euros: '10', cents: '95'},
    { id: 28, name:'Cheese and Turkey Breast (Halal)', price: '12,50', euros: '12', cents: '50'},
    
];