import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { MailserviceService } from '../_core/_services/mailservice.service';
import { DatePipe } from '@angular/common';
import { Openingstijden } from '../_core/_data/openingstijden';
import { OpeningstijdenModel } from '../_core/_models/openingstijdenmodel';

@Component({
  selector: 'app-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.css']
})
export class FormComponentComponent implements OnInit {
  @Input()
  formtype: string;
  WillsForm: UntypedFormGroup;
  email: string;
  name: string;
  message: string;
  subject: string;
  responseMessage: string;
  formtitle: string;
  timeList;
  datum: Date;
  reserverenform: boolean = false;
  contactform: boolean = false;
  validForm: boolean = true;
  emailSent: boolean = false;
  selectedDate = new UntypedFormControl(new Date());
  minDate: Date;
  maxDate: Date;
  datumString: String;
  selectedDay: number;
  openingstijdenArray = Openingstijden;
  selectedOpeninsTijd: OpeningstijdenModel;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private mailService: MailserviceService,
    private datepipe: DatePipe
  ) {



    this.minDate = new Date();
    var month = this.minDate.getMonth();
    var day = this.minDate.getDate();
    var year = new Date().getFullYear();
    this.maxDate = new Date(year + 1, month, day);
  }

  ngOnInit(): void {
    this.getFormtype(this.formtype);
    this.getTimeList();
    this.selectedOpeninsTijd = this.getOpeningstijden(new Date().getDay());
    this.getNewTimeList(this.selectedOpeninsTijd.open, this.selectedOpeninsTijd.sluit);
    this.createForm(this.formtype);
  }

  createForm(formtype) {
    if (formtype == "reserveren") {
      this.formtitle = "Reserveren";
      this.reserverenform = true;
      this.contactform = false;
      this.WillsForm = this.formBuilder.group({
        naam: [''],
        // email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
        email: ['', [Validators.required, Validators.email]],
        bericht: ['', []],
        telefoonnummer: ['', []],
        aantalpersonen: ['', [Validators.required]],
        datum: [this.minDate, [Validators.required]],
        tijd: [, [Validators.required]],
        selectedformtype: ['reserveren', []]
      });

      this.WillsForm.get("datum").valueChanges.subscribe(x => {
        this.selectedDay = x.getDay();
        this.selectedOpeninsTijd = this.getOpeningstijden(x.getDay());
        this.getNewTimeList(this.selectedOpeninsTijd.open, this.selectedOpeninsTijd.sluit);
      });
    } else if (formtype == "contact") {
      this.formtitle = "Contact formulier";
      this.reserverenform = false;
      this.contactform = true;
      this.WillsForm = this.formBuilder.group({
        naam: [''],
        email: ['', [Validators.required, Validators.email]],
        bericht: ['', [Validators.required,]],
        telefoonnummer: ['', []],
        selectedformtype: ['contact', []]
      });
    }

  }



  getFormtype(formtype) {
    if (formtype == "reserveren") {
      this.formtitle = "Reserveren";
    } else if (formtype == "contact") {
      this.formtitle = "Contact formulier";
    }
  }
  onFormChange(change) {
    this.createForm(change.value);
  }

  sendMail(): void {
    if (this.WillsForm.valid) {
      this.validForm = true;
    } else {
      this.validForm = false;
      setTimeout(() => {
        this.validForm = true;
      }, 7500);
      return;
    }
    if (this.formtype == "reserveren") {
      this.datum = this.WillsForm.controls['datum'].value;
      if (this.datum == null) {
        return;
      }

      this.datumString = this.datepipe.transform(this.datum, 'dd-MMM-yyyy');
      this.email = this.WillsForm.controls['email'].value;
      this.name = this.WillsForm.controls['email'].value;
      this.subject = "Reservering - " + this.datumString;
      this.message =
        `
      Naam: ${this.WillsForm.controls['naam'].value}
      Emailadres: ${this.WillsForm.controls['email'].value}
      Telefoonnummer: ${this.WillsForm.controls['telefoonnummer'].value}
  
      Datum: ${this.datumString}
      Tijd: ${this.WillsForm.controls['tijd'].value}
      Aantal Personen: ${this.WillsForm.controls['aantalpersonen'].value}

      Bericht: ${this.WillsForm.controls['bericht'].value}
      `

    } else if (this.formtype == "contact") {
      this.email = this.WillsForm.controls['email'].value;
      this.name = this.WillsForm.controls['email'].value;
      this.subject = "Vraag/Opmerking";
      this.message =
        `
    Naam: ${this.WillsForm.controls['naam'].value}
    Emailadres: ${this.WillsForm.controls['email'].value}
    Telefoonnummer: ${this.WillsForm.controls['telefoonnummer'].value}

    Bericht: ${this.WillsForm.controls['bericht'].value}
    `
    }

    let postVars = {
      email: this.email,
      name: this.email,
      message: this.message,
      subject: this.subject
    };



    this.mailService.sendMail('test', postVars).pipe(first())
      .subscribe(
        data => {
          this.responseMessage = data;
          if (this.responseMessage === "sent") {
            this.emailSent = true;
          }
        });
  }

  getNewTimeList(open: number, closed: number) {
    var arr = [], i, j;
    for (i = open; i <= closed - 1; i++) {
      for (j = 0; j < 2; j++) {
        arr.push(i + ":" + (j === 0 ? "00" : 30 * j));
      }
    }
    this.timeList = arr;
  }

  getTimeList() {
    var arr = [], i, j;
    for (i = 11; i <= 19; i++) {
      for (j = 0; j < 2; j++) {
        arr.push(i + ":" + (j === 0 ? "00" : 30 * j));
      }
    }
    this.timeList = arr;
  }

  getOpeningstijden(id): OpeningstijdenModel {
    return this.openingstijdenArray.find(x => x.id === id);
  }

}
