import { OpeningstijdenModel } from '../_models/openingstijdenmodel';

export const Openingstijden: OpeningstijdenModel[] = [
    { id: 1, dag: 'Maandag', open: 10, sluit: 19 },
    { id: 2, dag: 'Dinsdag', open: 10, sluit: 19 },
    { id: 3, dag: 'Woensdag', open: 10, sluit: 20 },
    { id: 4, dag: 'Donderdag', open: 10, sluit: 21 },
    { id: 5, dag: 'Vrijdag', open: 10, sluit: 20 },
    { id: 6, dag: 'Zaterdag', open: 10, sluit: 20 },
    { id: 7, dag: 'Zondag', open: 10, sluit: 19 }
]