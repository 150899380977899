<nav class="navbar navbram navbar-expand-sm willsred">
  <a class="navbar-brand w-15" routerLink="home">
    <img class="" src="./assets/img/logo/logo_willspancake_50px.svg" width="" alt="" *ngIf="!showNavLogoBig">
    <img class="d-block d-md-none" src="./assets/img//logo/logo_willspancake_50px.svg" width="" alt=""
      *ngIf="showNavLogoBig">
  </a>
  <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#collapsenavbar"
    aria-controls="collapsenavbar" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse willsnavbar" id="collapsenavbar">
    <ul class="nav mr-auto justify-content-center">
      <li class="nav-item"><a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
          routerLink="home" [routerLinkActive]="['isActive']">Home</a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
          routerLink="menu" [routerLinkActive]="['isActive']">Menukaart</a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
          routerLink="reserveren" [routerLinkActive]="['isActive']">Reserveren</a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
          routerLink="contact" [routerLinkActive]="['isActive']">Contact</a></li>
    </ul>
  </div>
  <div class="d-none d-lg-block">
    <div class="fontcolor">Volg ons!</div>
    <a href="https://facebook.com/willspancakehouse" target="_blank"><i
        class="fab fa-facebook-square fa-2x fontcolor"></i></a>
    &nbsp;
    <a href="https://instagram.com/willspancakehouse" target="_blank"><i
        class="fab fa-instagram fa-2x fontcolor"></i></a>
  </div>
</nav>
<div class="container-fluid navbar-xtr-image w-15 d-none d-lg-block" *ngIf="showNavLogoBig">
  <img class="" src="./assets/img/logo.png" width="100%" alt="">
</div>