<div *ngIf="emailSent">
    <div class="card card-contact">
        <div class="card-body">
            <h2 class="willscolor">Bericht verstuurd</h2>
            <p *ngIf="reserverenform">Bedankt voor het reserveren!
                <br>
                Indien nodig nemen wij zo snel mogelijk contact op met u.
            </p>

            <p *ngIf="contactform">Bedankt voor het bericht!
                <br>
                Indien nodig nemen wij zo snel mogelijk contact op met u.
            </p>

            <img class="bedanktimg rounded mx-auto d-block" src="./assets/img/formcomponent/bedankt.png">

        </div>
    </div>
</div>

<div class="card card-contact flex-row" *ngIf="!emailSent">
    <!-- <div class="card-img-left d-none d-md-flex">
    </div> -->
    <div class="card-body">
        <h4 class="card-title text-center">{{formtitle}}</h4>
        <form class="form-contact" [formGroup]="WillsForm">
            <mat-form-field appearance="outline" class="fullwidth">
                <mat-label>Naam</mat-label>
                <input required matInput placeholder="Naam" formControlName="naam">
            </mat-form-field>
            <mat-form-field appearance="outline" class="fullwidth">
                <mat-label>Emailadres</mat-label>
                <input required matInput placeholder="Emailadres" formControlName="email">
            </mat-form-field>
            <mat-form-field appearance="outline" class="fullwidth">
                <mat-label>Telefoon Nummer</mat-label>
                <input matInput placeholder="Telefoon Nummer" formControlName="telefoonnummer">
            </mat-form-field>
            <hr>
            <mat-form-field appearance="outline" *ngIf="(formtype==='contact')" class="fullwidth">
                <mat-label>Onderwerp</mat-label>
                <mat-select required formControlName="selectedformtype" (selectionChange)="onFormChange($event)">
                    <mat-option value="contact">
                        Vraag/Opmerking
                    </mat-option>
                    <mat-option value="reserveren">
                        Reserveren
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="reserverenform" class="fullwidth">
                <mat-label>Aantal personen</mat-label>
                <mat-select required formControlName="aantalpersonen">
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    <mat-option value="7">7</mat-option>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="8+">8+</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="reserverenform" class="halfwidth">
                <mat-label>Datum</mat-label>
                <input required matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker3"
                    formControlName="datum">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="reserverenform" class="halfwidth">
                <mat-label>Tijd</mat-label>
                <mat-select required formControlName="tijd">
                    <mat-option *ngFor="let time of timeList" [value]="time">
                        {{time}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fullwidth">
                <mat-label>Opmerkingen/Vragen</mat-label>
                <textarea matInput formControlName="bericht" cdkTextareaAutosize cdkAutosizeMinRows="4"
                    cdkAutosizeMaxRows="4"></textarea>
            </mat-form-field>
            <div class="verplicht">Velden met een * zijn verplicht</div>
            <div *ngIf="!validForm" class="alert alert-danger" id="emailRequired">
                Niet alle verplichte velden zijn ingevuld.
            </div>
            <button class="btn btn-lg btn-primary btn-block text-uppercase btn-wills-form" type="submit" id="registerButton"
                (click)="sendMail()">Verstuur</button>
        </form>
    </div>
</div>