import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Openingstijden } from '../_core/_data/openingstijden';
import { OpeningstijdenModel } from '../_core/_models/openingstijdenmodel';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  thisyear: String;
  openingstijdenArray = Openingstijden;
  constructor() { }

  ngOnInit(): void {

    this.thisyear = formatDate(new Date(), 'yyyy', 'en');
  }


}
