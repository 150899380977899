import { Component, OnInit } from '@angular/core';
import { MenuDataModel } from '../_core/_models/menudatamodel';
import { PannenkoekenData } from '../_core/_data/pannenkoekendata';
import { PoffertjesData } from '../_core/_data/poffertjesdata';
import { UitsmijterData } from '../_core/_data/uitsmijterdata';
import { TostiData } from '../_core/_data/tostidata';
import { ActivatedRoute, Router } from '@angular/router';
import { PannenkoekenEngData } from '../_core/_data/pannenkoekendata_en';
import { PoffertjesEngData } from '../_core/_data/poffertjesdata_en';
import { UitsmijterEngData } from '../_core/_data/uitsmijterdata_en';
import { TostiEngData } from '../_core/_data/tostidata_en';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  pannenkoeken: MenuDataModel[];
  poffertjes: MenuDataModel[];
  uitsmijters: MenuDataModel[];
  tostis: MenuDataModel[];

  hiddenPannenkoek = false;
  hiddenPoff = false;
  hiddenUitsmijter = false;
  hiddenTosti = false;

  dutchActive: boolean = false;
  englishActive: boolean = false;

  pannenkoekTitel = 'Pannenkoeken';
  poffTitel = 'Poffertjes';
  uitsmijtersTitel = 'Uitsmijters';
  tostiTitel = `Tosti's`;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(parameter => {
      this.dutchActive = false;
      this.englishActive = false;
      if (parameter.language === 'nl') {
        this.dutchActive = true;
        this.loadDutchMenu();
      } else if (parameter.language === 'en') {
        this.englishActive = true;
        this.loadEnglishMenu();
      } else {
        this.router.navigate(['/menu/nl']);
      }

    })



  }

  loadDutchMenu() {
    this.pannenkoekTitel = 'Pannenkoeken';
    this.poffTitel = 'Poffertjes';
    this.uitsmijtersTitel = 'Uitsmijters';
    this.tostiTitel = `Tosti's`;

    this.pannenkoeken = PannenkoekenData;
    this.poffertjes = PoffertjesData;
    this.uitsmijters = UitsmijterData;
    this.tostis = TostiData;
  }
  loadEnglishMenu() {
    this.pannenkoekTitel = 'Pancakes';
    this.poffTitel = 'Poffertjes (mini pancakes)';
    this.uitsmijtersTitel = 'Egg dishes';
    this.tostiTitel = 'Toasties';

    this.pannenkoeken = PannenkoekenEngData;
    this.poffertjes = PoffertjesEngData;
    this.uitsmijters = UitsmijterEngData;
    this.tostis = TostiEngData;
  }

  hidePoff(event: any) {
    this.addActiveClass(event);
    if (this.hiddenPoff) {
      this.hiddenPoff = false;
    } else {
      this.hiddenPoff = true;
    }
  }

  hidePannenkoek(event: any) {
    this.addActiveClass(event);
    if (this.hiddenPannenkoek) {
      this.hiddenPannenkoek = false;
    } else {
      this.hiddenPannenkoek = true;
    }
  }
  hideEi(event: any) {
    this.addActiveClass(event);
    if (this.hiddenUitsmijter) {
      this.hiddenUitsmijter = false;
    } else {
      this.hiddenUitsmijter = true;
    }
  }
  hideTosti(event: any) {
    this.addActiveClass(event);
    if (this.hiddenTosti) {
      this.hiddenTosti = false;
    } else {
      this.hiddenTosti = true;
    }
  }

  addActiveClass(event: any) {
    let clickedElement = event.target || event.srcElement;
    console.log(clickedElement.nodeName)
    if (clickedElement.nodeName === "BUTTON") {

      let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".active");
      // if a Button already has Class: .active
      console.log(isCertainButtonAlreadyActive)
      if (isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("active");
        return;
      }

      clickedElement.className += " active";
    }
  }

}
