import { MenuDataModel } from '../_models/menudatamodel';

export const TostiData: MenuDataModel[] = [
    { id: 1, name:'Ham of Kaas', price: '4,50', euros: '4', cents: '50'},
    { id: 2, name:'Ham en Kaas', price: '5,50', euros: '5', cents: '50'},
    { id: 3, name:'Kaas met Tomaat of Ananas of Gember of Salami', price: '5,75', euros: '5', cents: '75'},
    { id: 4, name:'Ham, Kaas en Ei', price: '6,00', euros: '6', cents: '00'},
    { id: 5, name:'Brie, Walnoten en Honing', price: '5,50', euros: '5', cents: '50'},
    { id: 6, name:'Brie en Sambal', price: '4,95', euros: '4', cents: '95'},
    { id: 7, name:'Tonijn, Kaas en Ei', price: '6,75', euros: '6', cents: '75'},
    { id: 8, name:'Kaas en Kalkoenfilet (Halal)', price: '5,95', euros: '5', cents: '95'},
    { id: 9, name:'Ham, Kaas, Spek, Champignon en Ei', price: '7,50', euros: '7', cents: '50'},
    { id: 10, name:'Mozzarella, Pesto en Tomaat', price: '6,50', euros: '6', cents: '50'}    
]
